import { MenuService } from './../services/app.menu.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Client, User } from '../services/interfaces';
import { AuthService } from '../services/auth.service';
import { ClientsService } from '../services/clients.service';



@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  userSub!: Subscription;
  user!: User;

  activeClientSub!: Subscription;
  activeClient: Client = {name: ''};

  constructor(
    public app: MenuService,
    private authService: AuthService,
    private router: Router,
    private clientsService: ClientsService
  ) { }

  ngOnInit() {

    this.userSub = this.authService.userDetails$
      .subscribe(user => {
        this.user = user;
      });

    this.activeClientSub = this.clientsService.activeClient$
      .subscribe(client => {
        this.activeClient = client;
      });

  }

  onEditOwnProfile(event: any) {
    this.app.onTopbarSubItemClick(event);
    this.router.navigate(['/user/edit/' + this.user.id]);
  }

  onLogOut(event: any) {
    this.app.onTopbarSubItemClick(event);
    this.authService.logout();
    this.app.onTopbarSubItemClick(event);
  }

  get clientProfileImg() {
      return 'assets/images/logo-blue.png';    
  }

  get userProfileImg() {
    if (!this.user) {
      return 'assets/default/defaultUser.png';
    }
    if (!this.user.profileImageUrl || this.user.profileImageUrl === '' ) {
      return 'assets/default/defaultUser.png';
    }
    return this.user.profileImageUrl;
  }


  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.activeClientSub) {
      this.activeClientSub.unsubscribe();
    }
  }

}
