

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCKU_hg2VC0L-UIajjR1LqIAvG7d5Bk7rI",
    authDomain: "project-yondi.firebaseapp.com",
    projectId: "project-yondi",
    storageBucket: "project-yondi.appspot.com",
    messagingSenderId: "271205593922",
    appId: "1:271205593922:web:582eb0ae032383ac190edb",
    measurementId: "G-F1QQXN5N7B"
  },

  resendVerification: 'https://us-central1-project-yondi.cloudfunctions.net/resendVerificationEmail',
  resetPassword: 'http://localhost:5001/project-yondi/us-central1/resetPassword',
  signup: 'https://us-central1-project-yondi.cloudfunctions.net/signup',

  cancelSubscription: 'https://us-central1-project-yondi.cloudfunctions.net/cancelSubscription',
  copyGlobalWidget: 'https://us-central1-project-yondi.cloudfunctions.net/copyGlobalWidget',
  // payfastGetUUID: 'http://localhost:5001/project-yondi/us-central1/onStartPayfast',
  createVideoTask: 'http://localhost:5001/project-yondi/us-central1/createVideoUploadTask',
  payfastMerchantId: 10000100,
  payfastMerchantKey: '46f0cd694581a',
  payfastPassphrase: null,
  payfastReturnUrl: 'http://localhost:4200/settings/client',
  payfastNotifyUrl: 'https://us-central1-project-yondi.cloudfunctions.net/payfastWebhook',
  payfastUrl: 'https://sandbox.payfast.co.za/eng/process',
  payfastCancel: 'http://localhost:5001/project-yondi/us-central1/cancelPayfast'
};

