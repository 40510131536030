<div class="topbar clearfix">
    <a routerLink="/" class="topbar-logo">
        <img class="logo big" routerLink="/" style="outline: none; cursor: pointer;" alt="image of client logo" src="assets/images/logo.svg"/>
        <img class="logo small" routerLink="/" style="outline: none; cursor: pointer;" alt="image of client logo" src="assets/images/logo.svg" />
    </a>

    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
        <i class="pi pi-align-left"></i>
    </a>

    <a href="#" class="profile" (click)="app.onTopbarMenuButtonClick($event)">
        <span class="username">
            <div>
                {{(user.name && user.surname) ? user.name + ' ' + user.surname : ''}}
            </div>
            <div>
                {{activeClient?.name ? activeClient.name : ''}}
            </div>
            
        </span>
        <img alt="profile image of {{ user.name }}" style="border-radius: 50%;" [src]="userProfileImg" />
        <i class="profile-submenu-icon pi pi-angle-down"></i>
    </a>

    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
        <li role="menuitem">
            <a href="#" (click)="onEditOwnProfile($event)">
                <i class="pi pi-fw pi-user-edit"></i>
                <span>Profile</span>
            </a>
        </li>
        <li role="menuitem">
            <a href="#" (click)="onLogOut($event)">
                <i class="pi pi-fw pi-sign-out"></i>
                <span>Logout</span>
            </a>
        </li>        
    </ul>
</div>