import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    ) 
  {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authService.checkForEmailVerified()) { return true; }

    this.router.navigate(['/auth/not-verified']);
    return false;
  }
}
