<div class="layout-actionbar">
    <div class="crummy">
        <p-breadcrumb [model]="items"></p-breadcrumb>
    </div>    

    <div class="layout-breadcrumb-buttons">
        <button 
            *ngFor="let label of newButtons" 
            pButton 
            icon="pi pi-plus" 
            type="button"
            class="p-button-outlined" 
            icon="pi pi-plus" 
            style="float:right" 
            (click)="onNew(label)"
            [label]="label">
        </button>
        <button 
            *ngIf="exportButtonShow" 
            (click)="onExportClicked()" 
            pButton 
            style="margin-top: 6px"
            icon="fa fa-file-csv" 
            label="Export" 
            class="p-button-outlined p-button-secondary">
        </button>
    </div>
</div>