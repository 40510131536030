import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();

    public showExportButton$ = new BehaviorSubject<boolean>(false);
    public exportButtonClicked$ = new Subject<string>(); //'send a now'

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }
}
