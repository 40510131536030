import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Client } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ClientsService implements OnDestroy {
  public activeClientId$ = new BehaviorSubject<string>(''); // gets called when signing in or dropdown
  public activeClientDomains$ = new BehaviorSubject<string[]>([]); 
  public activeClient$ = new BehaviorSubject<Client>({ id: '', name: '' }); // Can be called wherever
  
  private activeClientSub: Subscription;

  private userSub!: Subscription;
  lastUserId!: string;
  
  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private messageService: MessageService
  ) {

    this.activeClientSub = this.activeClientId$
      .pipe(switchMap(clientId => {
        console.log('clientId: ' + clientId);
        if (clientId != '') {
          window.sessionStorage.setItem('clientId', clientId);

          return this.afs.collection<Client>('Clients').doc(clientId).valueChanges({ idField: 'id' });
        }
        return new Observable<Client>();
      })).subscribe(client => {
        if (client) {
          this.activeClient$.next(client);
          if (client.domains) {
            this.activeClientDomains$.next(client.domains);
          } else {
            this.activeClientDomains$.next([]);
          }
        } else {
          this.activeClient$.next({});
          this.activeClientDomains$.next([]);
        }
      });

    this.userSub = this.authService.userDetails$
      .subscribe(user => {
        if (user.clientId && user.id !== this.lastUserId) {
          this.lastUserId = user.id;
          
          if (window.sessionStorage.getItem('clientId')) {
            this.activeClientId$.next(window.sessionStorage.getItem('clientId'));  
          } else {
            this.activeClientId$.next(user.clientId);
          }


        }
        if (user.id === '') {
          this.activeClientId$.next('')
        }
        // if (user.id) { 
 
 
        //   if (!this.activeClientId$.getValue()) {
        //     if (window.sessionStorage.getItem('clientId')) {
        //       this.activeClientId$.next(window.sessionStorage.getItem('clientId'));  
        //     } else {
        //       this.activeClientId$.next(user.clientId[0]);
        //     }
        //   }
 
 
         
        // } else {
        //   this.activeClientId$.next(null);
        // }

      });

    // this.activeClientSub = combineLatest([this.authService.userDetails$, this.activeClientId$])
    //   .pipe(switchMap(([user, activeClientId]) => {
    //     if (user.id !== '' && activeClientId) {
 
 
    //       window.sessionStorage.setItem('clientId', activeClientId);
 
 
         
    //       return this.afs.collection<Client>('Clients').doc(activeClientId).valueChanges({idField: 'id'});
    //     }
    //     this.activeClient$.next(null);
    //     return new Observable<Client>(); // Dummy as we logged out
    //   }))
    //   .subscribe(client => {
    //     if (client) {
    //       if (JSON.stringify(this.activeClient$.getValue()) !== JSON.stringify(client)) {
    //         this.activeClient$.next(client);
    //       }
    //     }
    //   });


  }


  public getClients$()  {
    return this.authService.userDetails$
      .pipe(switchMap(user => {
        if (user.id !== '') {
          return this.afs.collection<Client>('Clients').valueChanges({ idField: 'id' });
        }
        return new Observable<Client[]>();
      }))

  }

  public async getClient(id: string): Promise<Client > {
    if (!id || id === '') {
      return {};
    }
    try {
      const sn = await this.afs.collection('Clients').doc(id).get().toPromise()

      if (sn.exists) {
        return { ...sn.data() as Client, id: sn.id }
      }
      throw new Error();
    } catch (err) {
      this._handleError();
      throw new Error();
    }
  }
  
  public async updateClient(id: string, clientObj: Client) {
    try {
      const update: Client = {
        ...clientObj,
        updatedOn: new Date(),

      };

      await this.afs.collection('Clients').doc(id).update(update);
      return 'success';
    } catch (err) {
      this._handleError();
      throw new Error();
    }
  }

  public async updateDomainNames(updateObj: string[]) {
    try {
      await this.afs.collection('Clients').doc(this.activeClientId$.getValue()).update({domains: updateObj, updatedOn: new Date()});
      return 'success';
    } catch (err) {
      this._handleError();
      throw new Error();
    }
  }





  public async newClient(clientObj: Client) {

    const newObj: Client = {
      ...clientObj,
      createdOn: new Date(),
      updatedOn: new Date(),
    };
    try {
      await this.afs.collection('Clients').add(newObj);
      return;
    } catch (err) {
      console.log(err);
      this._handleError();
      return;
    }
  }

  private _handleError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please try again later' });
  }

  ngOnDestroy() {

    if (this.activeClientSub) {
      this.activeClientSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }

  }


}


