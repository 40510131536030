import { BreadcrumbService } from '../services/breadcrumb.service';
import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ActionbarService } from '../services/actionbar.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

  subscription: Subscription;

  items!: MenuItem[];

  exportButtonShowSub: Subscription;
  exportButtonShow = false;

  newButtonSub!: Subscription;
  newButtons: string[] = [];

  constructor(
    public breadcrumbService: BreadcrumbService,
    private actionbarService: ActionbarService
  ) {
    this.subscription = breadcrumbService.itemsHandler
      .subscribe(response => {
        this.items = response;
      });

    this.exportButtonShowSub = this.breadcrumbService.showExportButton$
      .subscribe(show => {
        this.exportButtonShow = show;
      })

      this.newButtonSub = this.actionbarService.newButtonBS
      .subscribe(labels => {
        this.newButtons = labels;
      });
  }

  onExportClicked() {
    this.breadcrumbService.exportButtonClicked$.next('now');
  }

  onNew(label: string) {
    this.actionbarService.newButtonClicked.next(label);
  }
  
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.exportButtonShowSub) {
      this.exportButtonShowSub.unsubscribe();
    }
  }
}
