
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject, Subscription } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { User } from './interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private canLogin = false;


  public currentRoute = new BehaviorSubject<string>('');
  public userDetails$ = new BehaviorSubject<User>({ id: '' });
  public gotAuthResponse = new BehaviorSubject<boolean>(false);

  private userType: 'Admin' | 'HCP' = 'Admin';
  private authSub: Subscription;



  public redirectUrl!: string;

  // private _usersCollection: AngularFirestoreCollection;
  private userSub!: Subscription;
  private hasRedirected = false;

  options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json' })
  };
  private emailVerified = false;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private httpClient: HttpClient,

  ) {

    this.authSub = this.afAuth.authState
      .subscribe(auth => {
        console.log(auth)
        this.gotAuthResponse.next(true);
        if (auth) {
          this.emailVerified = auth.emailVerified;

          this.canLogin = true;
          const currentUser = this.userDetails$.getValue();
          if (currentUser.id !== auth.uid) {
            this.subscribeToUser(auth.uid);
          }
        } else { // Not signed in
          this.resetAuth();
        }

      });


  }

  private resetAuth() {
    this.gotAuthResponse.next(true);
    this.canLogin = false;
    this.userDetails$.next({ id: '' });
    this.redirectUrl = '';
    this.router.navigate(['/auth/signin']);
    this.hasRedirected = false;
  }

  public login(email: string, password: string) {
    this.gotAuthResponse.next(false);
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(resp => {
          if (this.canLogin && !this.emailVerified) {
            this.router.navigate(['/auth/not-verified'])
          }
          resolve('success');
          // The subscriber will catch when ok
        })
        .catch(error => {
          console.log(error)
          reject(error);
        });
    });
  }

  public logout(): void {
    this.redirectUrl = '';
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    this.userDetails$.next({ id: '' })
    this.resetAuth();
    this.afAuth.signOut();
  }

  public checkAuthStatus() {
    return this.canLogin;
  }



  public checkForAdmin() {
    if (this.userType === 'Admin') {
      return true;
    }
    return false;
  }

  public checkForEmailVerified() {
    return this.emailVerified;
  }

  private subscribeToUser(userId: string) {
    
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    
    this.userSub = this.afs.collection<User>('Users').doc(userId).valueChanges()
      .subscribe(userDetails => {
        if (userDetails) {
          if (!userDetails.active) {
            this.logout();
            return;
          }
          this.userDetails$.next({ ...userDetails, id: userId });
          if (userDetails.role) {
            this.userType = userDetails.role;
          }
          this.gotAuthResponse.next(true);

          if (!this.hasRedirected) { // only run this on initial, not when user updates

            if (this.redirectUrl === '/' || !this.redirectUrl) {
              // User type check and then route
              this.router.navigate(['/widgets']);
              
            }
            else {
              this.router.navigateByUrl(this.redirectUrl);
              this.redirectUrl = '';
            }
            this.hasRedirected = true;

          } 
        }
      }, err => {
        console.log(err);
        // errors when logging out, ignoring
      });
  }

  public signup(formData: any) {
    return new Promise((resolve, reject) => {
      const data = {
        ...formData,
        apiKey: 'szdlfgnsdl;v hasoditvh wuio',

      };

      // this.afAuth.createUserWithEmailAndPassword(formData.email, formData.password)
      //   .then((resp) => {
          
      //     console.log(resp);

          this.httpClient.post<any>(environment.signup, data, this.options)
            .subscribe(resp => {
              this.gotAuthResponse.next(true);
              resolve(resp);
            }, err => {
              this.gotAuthResponse.next(true);
              console.log(err);
              reject('error');
            });
        // })
        // .catch (err => {
        //   console.log(err);
        //   reject(err)
        // })
      
    });
  }

  // public resetPassword(email: string) {
  //   return new Promise((resolve, reject) => {
  //     this.afAuth.sendPasswordResetEmail(email).then(() => {
  //       // Email sent.
  //       resolve({ status: 'success', message: 'Password reset mail sent' });
  //     }, error => {
  //       reject({ status: 'failure', message: error });
  //     });
  //   });
  // }

  public resetPassword(email: string) {
    return new Promise((res, reject) => {
      const data = {
        email,
        apiKey: 'szdlfgnsdl;v hasoditvh wuio',

      };
      
      this.httpClient.post<any>(environment.resetPassword, data, this.options)
        .subscribe(resp => {
          res(resp);
        }, err => {
          console.log(err);
          reject('error');
        });
    });
  }


  public resendVerification(email: string) {
    return new Promise((res, reject) => {
      const data = {
        email,
        apiKey: 'szdlfgnsdl;v hasoditvh wuio',

      };
      
      this.httpClient.post<any>(environment.resendVerification, data, this.options)
        .subscribe(resp => {
          res(resp);
        }, err => {
          console.log(err);
          reject('error');
        });
    });
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
