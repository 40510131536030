import { NgModule } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";

@NgModule({    
    providers: [
        MessageService,
        DialogService,
        ConfirmationService
    ],    
  })
  export class CoreModule { }