import { MenuService } from './../services/app.menu.service';
import {Component, Input, OnInit} from '@angular/core';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `
})
export class AppMenuComponent implements OnInit {

    @Input() model!: MenuItem[];

    constructor(public app: MenuService) {}

    ngOnInit() {
   
    }
}
