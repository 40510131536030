import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MenuService } from '../services/app.menu.service';
import { AuthService } from '../services/auth.service';
import { ClientsService } from '../services/clients.service';
import { User } from '../services/interfaces';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  activeUser: User = { superAdmin: false, id: '' }
  activeUserSub!: Subscription;

  dashboardMenu: MenuItem[] = [];
  settingsMenu: MenuItem[] = [];
  superAdminMenu: MenuItem[] = [];
  

  clientsSub!: Subscription;
  clientsDD: SelectItem[] = [];
  
  selectedClient!: string;
  selectedClientSub!: Subscription;
  
  constructor(
    public menuService: MenuService,
    private authService: AuthService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit(): void {
    
    this.activeUserSub = this.authService.userDetails$
      .subscribe(activeUser => {
        this.activeUser = activeUser;
        if (activeUser.superAdmin) {
          this.clientsSub = this.clientsService.getClients$()
            .subscribe(clients => {
              this.clientsDD = clients.map(el => ({label: el.name, value: el.id}));
            })
        }
      })

    this.selectedClientSub = this.clientsService.activeClientId$
      .subscribe(id => {
        this.selectedClient = id;
        this.dashboardMenu = [
          // { label: 'Dashboard', icon: 'fa fa-chart-line', routerLink: ['/dashboard'] , command: () => { this.menuService.onMenuItemClick() }},          
          { label: 'Widgets', icon: 'fas fa-tablet-alt', routerLink: ['/widgets'] , command: () => { this.menuService.onMenuItemClick() }},          
          { label: 'Requests', icon: 'fas fa-clipboard-check', routerLink: ['/requests'] , command: () => { this.menuService.onMenuItemClick() }},          
          // { label: 'Blocks', icon: 'fas fa-cubes', routerLink: ['/blocks'] , command: () => { this.menuService.onMenuItemClick() }},          
          { label: 'Contacts', icon: 'fas fa-address-book', routerLink: ['/contacts'] , command: () => { this.menuService.onMenuItemClick() }},          
        ];        
      });

    this.settingsMenu = [
      { label: 'Account', icon: 'fas fa-user-tie', routerLink: ['/settings/client'] , command: () => { this.menuService.onMenuItemClick()}},
      { label: 'Linked Domains', icon: 'fas fa-link', routerLink: ['/settings/linked-domains'] , command: () => { this.menuService.onMenuItemClick()}},
      { label: 'Users', icon: 'fas fa-user-friends', routerLink: ['/settings/users'] , command: () => { this.menuService.onMenuItemClick()}},
    ];

    this.superAdminMenu = [
      { label: 'Clients', icon: 'fas fa-users', routerLink: ['/super-admin/clients'] , command: () => { this.menuService.onMenuItemClick()}},      
      // { label: 'Subscriptions', icon: 'fas fa-credit-card', routerLink: ['/super-admin/subscriptions'] , command: () => { this.menuService.onMenuItemClick()}},      
    ];
  }

  onChangeClient(event: any) {
    this.clientsService.activeClientId$.next(event.value)
  }

  ngOnDestroy() {
    if (this.activeUserSub) {
      this.activeUserSub.unsubscribe();
    }
    if (this.clientsSub) {
      this.clientsSub.unsubscribe();
    }
    if (this.selectedClientSub) {
      this.selectedClientSub.unsubscribe();
    }
  }

}
