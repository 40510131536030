import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  template: `
    <div >
        <router-outlet></router-outlet>
    </div>`,
  styles: [
    `
      body { padding:0px }
    `
    ]
})
export class AuthLayoutComponent {

}
