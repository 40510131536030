import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActionbarDropdown } from './interfaces';


@Injectable({
  providedIn: 'root'
})
export class ActionbarService {
  
  newButtonBS = new BehaviorSubject<string[]>([]);
  newButtonClicked = new Subject<string>();

  filterDropdown$ = new BehaviorSubject<ActionbarDropdown[]>([]);
  filterDropdownChanged$ = new BehaviorSubject<{name: string, value: string}>({name: '', value: ''});

  exportButton = new BehaviorSubject<boolean>(false);
  exportNow = new Subject<string>();

  constructor() { }
}
