export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyCKU_hg2VC0L-UIajjR1LqIAvG7d5Bk7rI",
    authDomain: "project-yondi.firebaseapp.com",
    projectId: "project-yondi",
    storageBucket: "project-yondi.appspot.com",
    messagingSenderId: "271205593922",
    appId: "1:271205593922:web:582eb0ae032383ac190edb",
    measurementId: "G-F1QQXN5N7B"
   },   
   resendVerification: 'https://us-central1-project-yondi.cloudfunctions.net/resendVerificationEmail',
   resetPassword: 'https://us-central1-project-yondi.cloudfunctions.net/resetPassword',
   signup: 'https://us-central1-project-yondi.cloudfunctions.net/signup',
   copyGlobalWidget: 'https://us-central1-project-yondi.cloudfunctions.net/copyGlobalWidget',
  //  payfastGetUUID: 'https://us-central1-project-yondi.cloudfunctions.net/onStartPayfast',
   createVideoTask: 'https://us-central1-project-yondi.cloudfunctions.net/createVideoUploadTask',
   payfastMerchantId: 10000100,
   payfastMerchantKey: '46f0cd694581a',
   payfastPassphrase: null,
   payfastReturnUrl: 'https://app.viddi8.com/settings/client',
   payfastNotifyUrl: 'https://us-central1-project-yondi.cloudfunctions.net/payfastWebhook',
   payfastUrl: 'https://www.payfast.co.za/eng/process',
   payfastCancel: 'https://us-central1-project-yondi.cloudfunctions.net/cancelPayfast'

};