import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable(
    {providedIn: 'root'}
)
export class MenuService {

    private menuSource = new Subject<string>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    menuClick!: boolean;
    topbarMenuActive!: boolean;

    overlayMenuActive!: boolean;
    staticMenuDesktopInactive!: boolean;
    staticMenuMobileActive!: boolean;
    menuMode = 'static';

    topbarItemClick!: boolean;
    activeTopbarItem: any;

    menuHoverActive!: boolean;

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next();
    }

    onMenuButtonClick(event: any) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuItemClick(event: any = {}) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        // if (this.isOverlay()) {
        //     this.overlayMenuActive = !this.overlayMenuActive;
        // }
        if (this.isDesktop()) {
            // this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }



        this.topbarItemClick = false;
        this.menuClick = false;
    }

    isOverlay() {
        return this.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    onTopbarMenuButtonClick(event: any) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    onTopbarSubItemClick(event: any) {
        event.preventDefault();
        this.topbarMenuActive = false;
    }

    onTopbarItemClick(event: any, item: any) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }
    isHorizontal() {
        return this.menuMode === 'horizontal';
    }
    isSlim() {
        return this.menuMode === 'slim';
    }
    isMobile() {
        return window.innerWidth < 1025;
    }
    isStatic() {
        return this.menuMode === 'static';
    }
    onMenuClick() {
        this.menuClick = true;
    }
}
