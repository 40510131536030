import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './auth/layout.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './services/auth.guard.service';
import { EmailVerifiedGuard } from './services/email-verified.guard.service';

const routes: Routes = [
  {path: '', component: LayoutComponent, canActivate: [EmailVerifiedGuard, AuthGuard], data: {pageTitle: 'Fusion Funnel'}, 
    children: [
      {path: 'user',  loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
      {path: 'requests',  loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule)},
      {path: 'widgets',  loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)},
      {path: 'blocks',  loadChildren: () => import('./blocks/blocks.module').then(m => m.BlocksModule)},
      {path: 'contacts',  loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)},
      {path: 'settings',  loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)},
      {path: 'super-admin',  loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)},
    ]
  },


{path: 'auth', component: AuthLayoutComponent, 
  children: [
    { path: 'signin', loadChildren: () => import('./auth/signin/signin.module').then(m => m.SignInModule) },
    { path: 'forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
    { path: 'signup', loadChildren: () => import('./auth/signup/signup.module').then(m => m.SignUpModule) },
    {path: 'not-verified', loadChildren: () => import('./auth/not-verified/not-verified.module').then(m => m.NotVerifiedModule)}
  ]
},
{path: '**', component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
