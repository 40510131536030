<div 
    class="layout-wrapper" 
    (click)="menuService.onLayoutClick()" 
    [ngClass]="{'layout-horizontal': menuService.isHorizontal(),
                 'layout-overlay': menuService.isOverlay(),
                 'layout-static': menuService.isStatic(),
                 'layout-slim': menuService.isSlim(),
                 'layout-static-inactive': menuService.staticMenuDesktopInactive,
                 'layout-mobile-active': menuService.staticMenuMobileActive,
                 'layout-overlay-active': menuService.overlayMenuActive, 'p-input-filled': false, 'p-ripple-disabled': false}">

    <app-topbar></app-topbar>

    <div class="layout-menu-container" (click)="menuService.onMenuClick()">
        <div class="layout-menu-content">

            <div class="layout-menu-title">MENU</div>
            <app-menu [model]="dashboardMenu"></app-menu>

            <div>
                <div class="layout-menu-title">ADMINISTRATION</div>
                <app-menu [model]="settingsMenu"></app-menu>
            </div>

            <div *ngIf="activeUser.superAdmin">
                <div class="layout-menu-title">SUPER ADMIN</div>
                <p-dropdown 
                    [options]="clientsDD" 
                    [autoDisplayFirst]="false" 
                    [(ngModel)]="selectedClient" 
                    (onChange)="onChangeClient($event)">
                </p-dropdown>
                <app-menu [model]="superAdminMenu"></app-menu>
            </div>
        </div>
    </div>

    <div class="layout-content">

        <div class="layout-content-container">
            <app-breadcrumb></app-breadcrumb>
            <p-toast></p-toast>
            <div class="router-outlet">
                <router-outlet></router-outlet>
            </div>
        </div>

        <div class="layout-mask" *ngIf="menuService.staticMenuMobileActive"></div>
    </div>
</div>